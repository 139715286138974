<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" lg="12">
        <BaseCard heading="Historial de Depositos">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0">       
            <v-data-table
                :headers="headers"
                :items="histDepositos"
                item-key="id"
                sort-by="fecha"
                :sort-desc="true"
                :search="search"
                :items-per-page="10"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right'
                }"
                :custom-sort="customSort"
                :loading="loading"
                loading-text="Cargando... Un momento por favor"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                            class="pb-5"
                        ></v-text-field> 
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="success"
                            title="Refrescar"
                            @click="getDepositos"
                        >
                            <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.status="{ item }">
                <v-chip
                    :color="getColor(item.status)"
                    dark
                >
                    {{ item.status }}
                </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    v-if="item.status !== 'Pendiente' ? true : false"
                >
                    mdi-{{item.status === 'Aprobada' ? 'checkbox-marked-circle' : 'close-circle'}}-outline
                </v-icon>
                <v-btn
                    class="mini-boton"
                    @click="aprobar(item.id)"
                    fab
                    dark
                    color="success"
                    title="Aprobar"
                    v-if="item.status === 'Pendiente' ? true : false"
                >
                    <v-icon dark class="mini-icono">mdi-checkbox-marked-circle</v-icon>
                </v-btn>
                <v-btn
                    class="ml-2 mini-boton"
                    @click="rechazar(item.id)"
                    fab
                    dark
                    color="warning"
                    title="Rechazar"
                    v-if="item.status === 'Pendiente' ? true : false"
                >
                    <v-icon dark class="mini-icono">mdi-close-circle</v-icon>
                </v-btn>
                <v-btn
                    class="ml-2 mini-boton"
                    @click="copyRow(item)"
                    fab
                    dark
                    color="success"
                    title="Copiar datos"
                >
                    <v-icon dark class="mini-icono">mdi-content-copy</v-icon>
                </v-btn>
                </template>
            </v-data-table>
            </div>
            <v-dialog v-model="dialogAprobar" persistent max-width="290" >
            <v-card>
                <v-card-title class="text-h5">
                {{titleAprobar}}?
                </v-card-title>
                <v-card-text>
                Confirma que desea aprobar esta transacción.
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="dialogAprobar = false" >
                    No
                </v-btn>
                <v-btn color="primary" text @click="setAprobar" >
                    Si
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRechazar" persistent max-width="290" >
            <v-card>
                <v-card-title class="text-h5">
                {{titleRechazar}}?
                </v-card-title>
                <v-card-text>
                Confirma que desea rechazar esta transacción.
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="dialogRechazar = false" >
                    No
                </v-btn>
                <v-btn color="primary" text @click="setRechazar" >
                    Si
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialogSesion"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VsToast from '@vuesimple/vs-toast'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Depositos",

    data: () => ({
        usrToken: '',
        loading: true,
        dialogAprobar: false,
        dialogRechazar: false,
        dialogSesion: false,
        id_transaccion: null,
        titleAprobar: 'Aprobar Deposito',
        titleRechazar: 'Rechazar Deposito',
        search: '',
        histDepositos: [],
        headers: [
            { text: 'Id Transacción', value: 'id', width: 40 },
            { text: 'Fecha', value: 'fecha', width: 140 },
            { text: 'Tipo', align: 'start', sortable: false, value: 'tipo' },
            //{ text: 'Descripción', value: 'descripcion', visible: false },
            { text: 'Valor', value: 'valor' },
            { text: 'Formato', value: 'formato' },
            { text: 'Nombre Usuario', value: 'user' },
            { text: 'Email Usuario', value: 'email' },
            { text: 'Wallet Master', value: 'walletmaster' },
            { text: 'Status', value: 'status' },
            { text: 'Actions', value: 'actions', sortable: false, width: 125, align: 'center'},
        ],
    }),
  
    components: {
      sesionExpiredDialog
    },

    methods: {
        getColor (status) {
            if (status == 'Pendiente') return 'orange'
            else if (status == 'Rechazada') return 'red'
            else return 'green'
        },
        aprobar(id) {
            this.id_transaccion = id
            this.dialogAprobar = true
        },

        setAprobar() {
            this.dialogAprobar = false
            axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/transaccion/${this.id_transaccion}/aprobar`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respAprobar = response.data
                //console.log('Depositos...', respAprobar);
                if (!respAprobar.success){
                    VsToast.show({
                        title: this.titleAprobar,
                        message: respAprobar.msg,
                        variant: 'warning',
                        timeout: 2000,
                        type: "alert"
                    });
                    console.error('Depositos Error: ', respAprobar)
                }
                else {
                    VsToast.show({
                        title: this.titleAprobar,
                        message: 'Deposito aprobado correctamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.getDepositos()
                }
            })
            .catch(error => {
                console.error('Depositos Error: ', error)
                VsToast.show({
                    title: this.titleAprobar,
                    message: error.message,
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })
        },
        
        rechazar(id) {
            this.id_transaccion = id
            this.dialogRechazar = true
        },

        setRechazar() {
            this.dialogRechazar = false
            axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/transaccion/${this.id_transaccion}/rechazar`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respRechazar = response.data
                //console.log('Depositos...', respRechazar);
                if (!respRechazar.success){
                    VsToast.show({
                        title: this.titleRechazar,
                        message: respRechazar.msg,
                        variant: 'warning',
                        timeout: 2000,
                        type: "alert"
                    });
                    console.error('Depositos Error: ', respRechazar)
                }
                else {
                    VsToast.show({
                        title: this.titleRechazar,
                        message: 'Deposito rechazado correctamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.getDepositos()
                }
            })
            .catch(error => {
                console.error('Depositos Error: ', error)
                VsToast.show({
                    title: this.titleRechazar,
                    message: error.message,
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })
        },

        getDepositos() {            
            this.loading = true
            //Obtener los datos de las Depositos
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/transacciones?tp_transaccion=Deposito`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respDepositos = response.data
                //console.log('Depositos...', respDepositos);
                if (!respDepositos.success){
                    console.error('Depositos Error: ', respDepositos)
                }
                else {
                    this.histDepositos = respDepositos.data.map((trans) => {
                        //console.log(trans.valor, trans.valor.toLocaleString("es-VE", {style:"currency", currency:"USD"}))
                        return {
                            id: trans.id,
                            fecha: moment(trans.created_at).format('YYYY-MM-DD HH:mm'),
                            tipo: trans.tp_transaccion,
                            descripcion: trans.tx_descripcion,
                            valor: '$' + Number(trans.va_valor).toLocaleString("es-VE"),
                            user: !trans.user ? 'N/A' : trans.user.name,
                            email: !trans.user ? 'N/A' : trans.user.email,
                            formato: !trans.walletusers ? 'N/A' : trans.walletusers.tipo_wallet.nb_wallet,
                            walletuser: !trans.walletusers ? 'N/A' : trans.walletusers.wallet,
                            walletmaster: !trans.walletmaster ? 'N/A' : trans.walletmaster.wallet,
                            status: trans.tx_status
                        }
                    })
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Depositos Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })
        },
        
        customSort: function(items, index, isDesc) {
            console.log('index: ', index, isDesc)
            items.sort((a, b) => {
                ///if (index[0]=='date' || index[0]=='fecha') {
                if(Date.parse(a[index]) || Date.parse(b[index]) ){
                    //console.log('a: ', a[index], new Date(a[index]))
                    //console.log('b: ', b[index], new Date(b[index]))
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },
        
        async copyRow(copyRow) {
            delete copyRow.id
            delete copyRow.descripcion
            delete copyRow.walletuser
            let mytext = JSON.stringify(copyRow)
            mytext = mytext.replace('{', '').replace('}', '').replace(/,"/g, '\n"').replace(/":/g, ': ').replace(/"/g, '')
            let input = document.createElement('textarea');
            input.setAttribute('value', mytext);
            input.value = mytext;        
            document.body.appendChild(input);
            try {
                input.select();    
                input.click();     
                input.focus();
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                if(successful){
                    VsToast.show({
                        //title: this.titleRechazar,
                        message: 'Texto copiado...',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                console.log('Testing code was copied ' + successful + ' ' + msg, mytext);
                
            } catch (err) {
                console.log('Oops, unable to copy');
            }
            document.body.removeChild(input);          
        },
    },
  
  async created () {
    this.usrToken = localStorage.getItem("usrToken")
    this.getDepositos()
  }
};
</script>

<style>
.mini-icono {
    height: 16px !important;
    font-size: 16px !important;
    width: 16px !important;
}
.mini-boton {
    height: 24px !important;
    width: 24px !important;
}
</style>